// Change website animations
const animation = {
  // make it false to switch off fade-up animation
  animate: true,
  // animation playing duration
  duration: 750,
  // if true, animation plays only once when element comes on screen
  once: false,
};
// Change your display name on tha landing display
const header = {
  name: "MJ Anselmo",
};
const background = {
  // Options: Snow or Particle
  type: "Particle",
};
// Write a para about yourself here
// To update your image, go to './styles/images.css'
const section2title = "About Me!";
const about = {
  paragraph:
    "I specialize in developing robust cybersecurity solutions, IT integration, and efficient networking to enhance workplace productivity and security. With a passion for continuous learning and technology advancement, I ensure systems are not only up to date but also seamless and secure in operation. Outside of tech, my enthusiasm takes flight as a private pilot, exploring new heights both professionally and personally.",
};
// Edit your skill and the percentage you know about it
// To Add a skill, copy any one below and paste it after the last comma
const skillsBar = [
  {
    name: "Networking",
    // To add a custom svg instead of font-awesome icons, add svg path below otherwise just comment it out
    //svg: "M0 32l34.9 395.8L191.5 480l157.6-52.2L384 32H0zm308.2 127.9H124.4l4.1 49.4h175.6l-13.6 148.4-97.9 27v.3h-1.1l-98.7-27.3-6-75.8h47.7L138 320l53.5 14.5 53.7-14.5 6-62.2H84.3L71.5 112.2h241.1l-4.4 47.7z",
    //svg: "M0 224V416c0 17.7 14.3 32 32 32H96V336c0-8.8 7.2-16 16-16s16 7.2 16 16V448h64V336c0-8.8 7.2-16 16-16s16 7.2 16 16V448h64V336c0-8.8 7.2-16 16-16s16 7.2 16 16V448h64V336c0-8.8 7.2-16 16-16s16 7.2 16 16V448h64c17.7 0 32-14.3 32-32V224c0-17.7-14.3-32-32-32H448V160c0-17.7-14.3-32-32-32H384V96c0-17.7-14.3-32-32-32H160c-17.7 0-32 14.3-32 32v32H96c-17.7 0-32 14.3-32 32v32H32c-17.7 0-32 14.3-32 32z",
    faClass: "fas fa-network-wired",
  },
  {
    name: "Windows",
    // svg: '',
    faClass: "fab fa-windows",
  },
  {
    name: "macOS",
    // svg: '',
    faClass: "fab fa-apple",
  },
  {
    name: "Linux",
    // svg: '',
    faClass: "fab fa-linux",
  },
  {
    name: "VMware",
    // svg: '',
    faClass: "fas fa-server",
  },
  {
    name: "M365",
    // svg: '',
    faClass: "fab fa-microsoft",
  },
  {
    name: "Gsuite",
    // svg: '',
    faClass: "fab fa-google",
  },
  {
    name: "Database",
    // svg: '',
    faClass: "fas fa-database",
  },
  {
    name: "AWS",
    // svg: '',
    faClass: "fab fa-aws",
  },
  {
    name: "Automation",
    // svg: '',
    faClass: "fas fa-robot ",
  },
];
// Edit your projects, its name, your skills used to make it, and the url.
// You can omit freely anything if you dont have it
// To Add a Project, copy any one below and paste it after the last comma and increment the id's project number
const section3Title = "Past Projects";
const projects = [
  {
    // Add image in './styles/images.css' in #project1
    id: "project1",
    name: "Plex Media Server",
    skills: ["Networking, Cloud, Hardware Development"],
    url: "https://github.com/Manselm0",
  },
  {
    // Add image in './styles/images.css' in #project2
    id: "project2",
    name: "Hal the AI Chat Bot",
    skills: ["TS, JS, Chat GPT 3.0"],
    url: "https://github.com/Manselm0",
  },
  {
    // Add image in './styles/images.css' in #project3
    id: "project3",
    name: "Self-Hosted Website",
    skills: ["HTML, JS, React, Cloud"],
    url: "https://github.com/Manselm0",
  },
];
// Edit your Miscellaneous Activities, its name and the url.
// You can omit freely anything if you dont have it
// To Add a Activity, copy any one below and paste it after the last comma and increment the id's Miscellaneous number
const section4Title = "Current Project ";
const miscellaneous = [
  {
    // Add image in './styles/images.css' in #misc1
    id: "misc1",
    name: "Home Lab",
    url: "https://github.com/Manselm0",
  },
];
// Contact form text, and Formspree link(to send a submit contact through their API as in contact.js)
// To get your own jotform link, go to https://formspree.io/
// If you hacve the link already, paste it in the contactUrl below
const section5Title = "Get in Touch";
const contact = {
  pitch:
    "Feel free to reach out to discuss potential opportunities, collaborations, or if you just want to connect and talk tech!",
  copyright: "MJ Anselmo",
  contactUrl: "https://formspree.io/f/xwkggeoe",
};
// Paste your respective social media links. You can omit any if you dont have it
// Upload your resume in your drive, get the shaareable link and paste it in the resume section
const social = {
  github: "https://github.com/Manselm0",
  //facebook: "https://facebook.com",
  // twitter: "https://twitter.com",
  instagram: "https://instagram.com/mj_anselmo",
  linkedin: "https://linkedin.com/in/michael-anselmo-6b932225a/",
  //resume: "https://novoresume.com/",
};
// Dont change anything here
export {
  animation,
  header,
  background,
  about,
  skillsBar,
  projects,
  miscellaneous,
  contact,
  social,
  section2title,
  section3Title,
  section4Title,
  section5Title,
};
