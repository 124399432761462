// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Anonymous+Pro:wght@700&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ":root {\n    --primary-color: #444d55;\n    --hover-color: #406791;\n}", "",{"version":3,"sources":["webpack://src/styles/style.css"],"names":[],"mappings":"AAIA;IACI,wBAAwB;IACxB,sBAAsB;AAC1B","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap');\n@import url('https://fonts.googleapis.com/css2?family=Anonymous+Pro:wght@700&display=swap');\n@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro');\n\n:root {\n    --primary-color: #444d55;\n    --hover-color: #406791;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
